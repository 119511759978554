




















import { PhotoCollectionObject, PhotoCollection } from "@/interfaces/models";
import { Vue, Component, Prop } from "vue-property-decorator";
import AxiosInstanceBase from "@/api/config";

@Component
export default class PhotoCollectionGallery extends Vue {
  @Prop({ required: true }) photo_collection!: PhotoCollectionObject;
  @Prop({ required: true }) name?: string;
  @Prop({ default: "white" }) color?: string;
  @Prop({ required: true }) display_names?: boolean;
  activeIndex = 0;
  visible = false;
  collection: PhotoCollection | null = null;

  async created(): Promise<void> {
    try {
      const res = await AxiosInstanceBase.get(
        `photo-collections/${this.photo_collection.photo_collection.data.id}?populate=deep`
      );
      if (res.status == 200) {
        this.collection = res.data.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  get host() {
    return this.$root.$data.host;
  }

  get images(): { title: string; src: string }[] {
    const photos = this.collection?.attributes.photos as any[];
    const images =
      photos?.map((item: any) => {
        return {
          title: item.photo.data.attributes.name,
          src:
            this.$root.$data.host +
            item.photo.data.attributes.image.data.attributes.url
        };
      }) ?? [];
    return images ?? [];
  }

  openLightbox(index: number): void {
    this.activeIndex = index;
    this.visible = true;
  }

  hideLightbox(): void {
    this.visible = false;
  }
}
